import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

import Fade from 'react-reveal/Fade'

class Press extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    let allPosts = data.allMdx.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension no_bg press aligncenter">
            <div className="bg">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={'25%'}>
                  <h2>The word is out</h2>
                </Fade>

                <Fade bottom duration={1000} delay={250} distance={'25%'}>
                  <p>Read about Knocking in the news</p>
                </Fade>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title="Press"
          keywords={[
            'Knocking',
            'Knocking news',
            'Ecommerce solution',
            'Media trends',
          ]}
          description="Read about our latest milestones and how our unique ecommerce solution is impacting media trends."
        />

        <div className="page-content pages-press">
          <div className="section-press">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  {allPosts.map(({ node }) => (
                    <Link to={node.fields.slug} className="press-item">
                      <p className="cp-date">{node.frontmatter.date}</p>

                      <p className="cp-title">{node.frontmatter.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <PreFooter
          title="Ready to take off with Knocking?"
          linkText="Let’s talk"
          linkTo="/contact"
        />
      </Layout>
    )
  }
}

export default Press

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "press" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
